@media print {
	@page {
		background-color: white;
		margin: 0;
	}
}

.grecaptcha-badge {
	display: none !important; /* this is needed to hide the recaptcha tag */
}
